html, #root, .App {
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.map-container {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 0;
}

.marker {
    background-image: url('assets/icons/SVG/video.svg');
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.mapboxgl-popup-content {
    min-width: fit-content;
    padding: 1rem;
    border-radius: 0.5rem;
	background-color: rgba(14, 15, 15, 1);
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    border-bottom-color: rgba(14, 15, 15, 1) !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: rgba(14, 15, 15, 1) !important;
}

.mapboxgl-popup-close-button {
    display: none;
}

.camera-title {
    display: block;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    color: #f2f2f2;
    margin-top: 0.5rem;
    font-size: 1rem;
}

.camera-video {
    border-radius: 0.25rem;
}