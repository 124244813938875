.homeOuter {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.header {
	display: block;
}

.title {
	display: inline-block;
	color: #C5D6D5;
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	font-size: 1.5rem;
	margin-left: 40px;
}

.homeSearchHolder {
	margin-top: 2rem;
}

.redColour {
	color: #D64C4C;
}

.footer {
	display: inline-block;
	color: rgba(197, 214, 213, 0.3);
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	font-size: 1rem;
}

.lastUpdate {
	color: #C5D6D5;
}