.outer {
	position: absolute;
	height: 1.75rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.back {
	width: 24px;
	height: 24px;
	border-radius: 10px;
	background-color: rgba(214, 76, 76, 0.3);
	animation: pulse 2s ease-in-out infinite;
}

@keyframes pulse {
	0% {
		background-color: rgba(214, 76, 76, 0.3);
		transform: scale(1);
	}
	50% {
		background-color: rgba(214, 76, 76, 0.2);
		transform: scale(1.1);
	}
	100% {
		background-color: rgba(214, 76, 76, 0.3);
		transform: scale(1);
	}
}

.centre {
	width: 12px;
	height: 12px;
	background-color: rgba(214, 76, 76, 1);
	border-radius: 4.4px;
	position: absolute;
}